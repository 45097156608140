<template>
  <v-card>
    <div class="d-flex justify-start px-2 pt-2">
      <v-btn icon @click="$emit('closeModal'), $v.newContact.$reset()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-card-title class="secondary--text px-7 pb-7">
      {{ isUpdate? "Actualización de": "Registro" }} contacto</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-select :items="document_types" item-text="nombre" item-value="id" outlined
            @blur="$v.newContact.id_tipo_documento.$touch()" :error-messages="documentTypeErrors" @change="setMask"
            label="Tipo de documento *" v-model="newContact.id_tipo_documento">
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="newContact.numero_documento" label="Número de documento *" outlined :maxLength="50"
            @input="$v.newContact.numero_documento.$touch()"
            @blur="$v.newContact.numero_documento.$touch()" :error-messages="documentNumberErrors"
            :append-icon="dui_verified ? 'mdi mdi-check-circle' : null" :disabled="dui_verified">
          </v-text-field>
        </v-col>
        <!-- ----- Validacion de DUI ----- -->
        <v-col cols="12" class="mx-3" v-if="newContact.id_tipo_documento === 1">
          <v-row>
            <v-col class="mb-15" cols="4">
              <v-btn :disabled="is_verify_dui_btn_disabled" :loading="is_dui_verify_loading" @click="verifyDui"
                color="success">Verificar</v-btn>
            </v-col>
            <v-col v-if="dui_verified" cols="8">
              <v-row>
                <v-col cols="6">
                  <p class="mb-0">Nombre del contacto:</p>
                  <strong class="primary--text">{{ newContact.nombre }}</strong>
                </v-col>
                <v-col cols="6">
                  <p class="mb-0">DUI:</p>
                  <strong class="primary--text">{{
                    newContact.numero_documento
                  }}</strong>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <!-- ----- Validacion de DUI ----- -->
        <v-col v-else cols="12">
          <v-text-field v-model="newContact.nombre" label="Nombre *" outlined @input="$v.newContact.nombre.$touch()"
            :error-messages="nameErrors" :maxLength="150">
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="newContact.correo_electronico" label="Correo electrónico *" outlined
            @input="$v.newContact.correo_electronico.$touch()" :error-messages="emailErrors" :maxLength="100">
          </v-text-field>
        </v-col>
<!--        <v-col cols="12">-->
<!--          <v-select label="Tipo de contacto *" :items="contacts_type" item-text="nombre" item-value="id" outlined-->
<!--            v-model="newContact.id_tipo_contacto" ></v-select>-->
<!--        </v-col>-->
        <v-col cols="12">
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field label="Teléfono *" class="mr-1" outlined v-mask="'############'"
                v-model="newContact.numero_telefono" @input="$v.newContact.numero_telefono.$touch()"
                :error-messages="phoneNumberErrors"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field label="Teléfono secundario" class="ml-1" outlined v-mask="'############'"
                v-model="newContact.numero_telefono_secundario"
                @input="$v.newContact.numero_telefono_secundario.$touch()"
                :error-messages="secPhoneNumberErrors"></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <v-textarea v-model="newContact.direccion" label="Dirección *" outlined :maxLength="250"
            @input="$v.newContact.direccion.$touch()" :error-messages="addressErrors"></v-textarea>
        </v-col>
        <v-col cols="12" sm="6">
          <v-textarea v-model="newContact.datos_adicionales" label="Datos adicionales" outlined :maxLength="250"
            ></v-textarea>
        </v-col>
<!--        <v-col cols="12" class="d-flex">-->
<!--          <p class="black&#45;&#45;text">¿Enviará cotizaciones a UCP?</p>-->
<!--          <v-checkbox v-model="newContact.cotizacion_uaci" label="" class="mt-n1"></v-checkbox>-->
<!--        </v-col>-->
<!--        <v-col cols="12">-->
<!--          <v-row>-->
<!--            <v-col cols="12" md="9">-->
<!--              <v-autocomplete-->
<!--                v-model="searchParam"-->
<!--                :items="listado_obs_select_new"-->
<!--                item-text="codigoNombre"-->
<!--                :filter="filterObsSelect"-->
<!--                item-value="id"-->
<!--                label="OBS que presta *"-->
<!--                @keyup="isLoading = true"-->
<!--                :loading="isLoading"-->
<!--                outlined-->
<!--                clearable-->
<!--                @change="setObsSeleected()"-->
<!--                return-object-->
<!--                :maxLength="200"-->
<!--                hint="Ingrese el nombre o código del OBS que desea agregar"-->
<!--                persistent-hint-->
<!--                :error-messages="selectedObsErrors"-->
<!--                :search-input.sync="searchValidator"-->
<!--                @click:clear="setObsSeleected(null)"-->
<!--                @update:search-input="searchObsSelect"-->
<!--                hide-no-data-->
<!--              >-->
<!--              </v-autocomplete>-->
<!--            </v-col>-->
<!--            <v-col cols="12" md="3">-->
<!--              <v-btn class="secondary" x-large block @click="insertOption()">Agregar OBS</v-btn>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--          <v-col cols="12">-->
<!--            <v-data-table :headers="headers" :items="newContactObsList" item-key="item" :items-per-page="5"-->
<!--              class="text-center">-->
<!--              <template v-slot:[`item.actions`]="{ item }">-->
<!--                <v-tooltip right>-->
<!--                  <template v-slot:activator="{ on, attrs }">-->
<!--                    <v-icon v-bind="attrs" v-on="on" @click="deleteOption(item)">-->
<!--                      mdi-delete-->
<!--                    </v-icon>-->
<!--                  </template>-->
<!--                  <span> Eliminar </span>-->
<!--                </v-tooltip>-->
<!--              </template>-->
<!--            </v-data-table>-->
<!--          </v-col>-->
<!--        </v-col>-->
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-center py-4">
      <v-btn @click="saveContact()" class="primary px-8" x-large>
        {{ isUpdate? "Actualizar": "Agregar" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required, email, helpers, requiredIf, minLength, maxLength } from 'vuelidate/lib/validators';
import agregarLista from "./AgregarListaComponent.vue";
import { mapState, mapMutations, mapActions } from "vuex";

const alpha = helpers.regex("alpha", /^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*$/);

const alphaNum = helpers.regex("alphaNum", /^[a-zA-ZÀ-ÿ0-9-#,.;/\u00f1\u00d1 ]*$/);

// Se requiere validar números internacionales también
// const phoneValid = helpers.regex("phoneValid", /^(?=\d{8}$)(2|6|7)\d+/);

function setCustomValidator(value) {
  if (this.mask === "########-#") {
    const regDui = /^\d{8}-\d{1}$/;
    if (value) {
      return regDui.test(value);
    }
    return true;
  } else if (this.newContact.id_tipo_documento == 5) {
    const regMatriculaComercio = /^\d{1,30}$/;
    if (value) {
      return regMatriculaComercio.test(value);
    }
    return true;
  } else {
    const regAlphaNum = /^[a-zA-Z0-9_-]*$/;
    if (value) {
      return regAlphaNum.test(value);
    }
    return true;
  }
}
export default {
  name: "RegistrarContactoModalComponent",
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  components: { agregarLista },
  validations() {
    return ({
      newContact: {
        id_tipo_documento: { required },
        numero_documento: {
          required,
          setCustomValidator,
        },
        ...this.newContact.id_tipo_documento !== 1 && ({ nombre: { required, alpha }}),
        correo_electronico: { required, email },
        // id_tipo_contacto: { required },
        numero_telefono: { required, minLength: minLength(8), maxLength: maxLength(12) },
        numero_telefono_secundario: { minLength: minLength(8), maxLength: maxLength(12) },
        direccion: { required, alphaNum },
      },
      // selected_obs: {
      //   required: requiredIf(function () {
      //     return this.newContactObsList.length == 0 ? true : false;
      //   }),
      // },
    });
  },
  data: () => ({
    headers: [
      { text: "OBS", align: "start", value: "nombre", sortable: false },
      { text: "Eliminar", align: "center", value: "actions", sortable: false },
    ],
    contacts_type: [],
    document_types: [],
    obs_list: [],
    selected_obs: null,
    mask: null,
    searchParam: "",
    listado_obs_select_new: [],
    isLoading: false,
    searchValidator: null,
    obs_timer: null,
  }),
  computed: {
    ...mapState("proveedores", [
      "newContact",
      "newContactObsList",
      "is_dui_verify_loading",
      "is_verify_dui_btn_disabled",
      "dui_verified",
    ]),
    obs_list_mapped() {
      return this.obs_list.map((col) => ({
        id: col.id,
        nombre: `${col.codigo} - ${col.nombre}`,
      }));
    },
    documentTypeErrors() {
      const errors = [];
      if (!this.$v.newContact.id_tipo_documento.$dirty) return errors;
      !this.$v.newContact.id_tipo_documento.required &&
        errors.push("Tipo de documento es obligatorio");
      return errors;
    },
    documentNumberErrors() {
      const errors = [];
      if (!this.$v.newContact.numero_documento.$dirty) return errors;

      !this.$v.newContact.numero_documento.required && errors.push("Número de documento es obligatorio");
      !this.$v.newContact.numero_documento.setCustomValidator && errors.push("Ingrese un número de documento válido");

      
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.newContact.nombre.$dirty) return errors;
      !this.$v.newContact.nombre.required &&
        errors.push("Nombre es obligatorio");
      !this.$v.newContact.nombre.alpha &&
        errors.push("Nombre solo admite letras");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.newContact.correo_electronico.$dirty) return errors;
      !this.$v.newContact.correo_electronico.required &&
        errors.push("Correo electrónico es obligatorio");
      !this.$v.newContact.correo_electronico.email &&
        errors.push("Ingrese un correo electrónico válido");
      return errors;
    },
    // contactTypeErrors() {
    //   const errors = [];
    //   if (!this.$v.newContact.id_tipo_contacto.$dirty) return errors;
    //   !this.$v.newContact.id_tipo_contacto.required &&
    //     errors.push("Tipo de contacto es obligatorio");
    //   return errors;
    // },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.newContact.numero_telefono.$dirty) return errors;
      !this.$v.newContact.numero_telefono.required &&
        errors.push("Teléfono es obligatorio");
      !this.$v.newContact.numero_telefono.minLength &&
        errors.push("Teléfono debe tener al menos 8 dígitos");
      !this.$v.newContact.numero_telefono.maxLength &&
        errors.push("Teléfono debe tener máximo 12 dígitos");
      return errors;
    },
    secPhoneNumberErrors() {
      const errors = [];
      if (!this.$v.newContact.numero_telefono_secundario.$dirty) return errors;
      !this.$v.newContact.numero_telefono_secundario.minLength &&
        errors.push("Teléfono secundario debe tener al menos 8 dígitos");
      !this.$v.newContact.numero_telefono_secundario.maxLength &&
        errors.push("Teléfono secundario debe tener máximo 12 dígitos");
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.newContact.direccion.$dirty) return errors;
      !this.$v.newContact.direccion.required &&
        errors.push("Dirección es obligatorio");
      !this.$v.newContact.direccion.alphaNum &&
        errors.push("Dirección solo admite letras y números");
      return errors;
    },
    // selectedObsErrors() {
    //   const errors = [];
    //   if (!this.$v.selected_obs.$dirty) return errors;
    //   !this.$v.selected_obs.required &&
    //     errors.push("Debe seleccionar al menos un OBS");
    //   return errors;
    // },
  },
  methods: {
    ...mapMutations("proveedores", ["clearContactInfo", "clearNewObsList"]),
    ...mapActions("proveedores", [
      "verifyDui",
      "clearVerifyDui",
      "deleteObsContactItem",
    ]),
    filterObsSelect(item, queryText, itemText) {
      const words = queryText.split(" ");
      const hasWords = itemText.split(" ");

      return words.some((word) => {
        return hasWords.some((hasWord) => {
          return hasWord.toLowerCase().indexOf(word.toLowerCase()) > -1;
        });
      });
    },
    insertOption() {
      if (this.selected_obs == null || this.selected_obs == undefined) return;


      if (this.selected_obs != null && Object.keys(this.selected_obs).length > 0)

      // Antes de agregar un nuevo obs verificamos que no este en newContactObsList y si ya existe no lo agregamos y mostramos un mensaje de error
      if (this.newContactObsList.find((obs) => obs.id === this.selected_obs.id)) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "El OBS seleccionado ya se encuentra en la lista",
        });
        this.selected_obs = null;
        return;
      }

      /**
       * local: true, indica que el OBS es local y no se ha guardado en la base de datos
       * por lo cual para eliminarlo solo se debe eliminar de la lista de OBS, y no
       * será necesario hacer una petición al servidor para eliminarlo
       */
      this.newContactObsList.push({
        id: this.selected_obs.id,
        nombre: this.selected_obs.nombre,
        local: true,
      });

      this.selected_obs = null;
      this.searchParam = null;
    },
    deleteOption(item) {
      this.deleteObsContactItem(item.id);
    },
    async fetchContactType() {
      const response = await this.services.Contactos.getContactsTypes();
      if (response.status === 200) {
        this.contacts_type = response?.data;
      }
    },
    async getDocumentTypeList() {
      const response = await this.services.Documentos.getDocumentTypeList({
        id_categoria_documento: 1,
      });
      if (response.status === 200) {
        this.document_types = response?.data;
      }
    },
    async getOBS() {
      const response = await this.services.Obs.getObsList({
        pagination: false,
        productos: true,
      });
      if (response.status === 200) {
        this.obs_list = response?.data;
      }
    },
    async saveContact() {

      // Verificamos que se haya validado el DUI si el id del tipo de documento es 1
      if (this.newContact.id_tipo_documento === 1 && !this.dui_verified) {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "Verifique el DUI",
        });
        return;
      }
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let numeros_telefono = [];
        if (this.newContact.numero_telefono)
          numeros_telefono.push({
            titulo: "Teléfono",
            telefono: this.newContact.numero_telefono,
          });
        if (this.newContact.numero_telefono_secundario)
          numeros_telefono.push({
            titulo: "Teléfono secundario",
            telefono: this.newContact.numero_telefono_secundario,
          });
        const params = {
          ...this.newContact,
          numeros_telefono,
          obs: this.newContactObsList.map((col) => col.id),
        };
        let response = null;

        if (this.isUpdate) {
          response = await this.services.Proveedores.putProviderContacts(
            this.newContact.id,
            params
          );
        } else {
          response = await this.services.Proveedores.postProviderContacts(
            params
          );
        }
        if (response.status === 201 || response.status === 200) {
          let resMessage = `Contacto ${this.isUpdate ? "actualizado con éxito" : "agregado correctamente"
            }`;
          this.temporalAlert({
            show: true,
            type: "success",
            message: resMessage,
          });
          this.clearContactInfo();
          this.clearNewObsList();
          this.$v.newContact.$reset();
          this.$emit("closeModal");
          this.$emit("reloadContactList");
        }
      }
    },
    setMask() {
      let id = this.newContact.id_tipo_documento;
      let documento = this.document_types.find((doc) => {
        return doc.id === id;
      });
      this.mask = documento.formato;

      // Limpiamos la verificación de DUI
      this.clearVerifyDui();
    },
    async searchObsSelect(value) {
      const regex_alphaNum = /^[a-zA-ZÀ-ÿ0-9- ]*$/;
      if (!regex_alphaNum.test(value)) {
        this.searchValidator = await value.replace(value, "");
      } else {
        if (this.obs_timer) {
          clearTimeout(this.obs_timer);
        }

        if (this.selected_obs !== null) {
          return;
        }

        this.obs_timer = setTimeout(async () => {
          let params = {
            search: value,
            inactivos: true,
            mostrar_todos: true,
          };

          let codigoObs = Number(value);

          if (
            (codigoObs && codigoObs != NaN && value?.length >= 2) ||
            (value?.length > 2 && isNaN(codigoObs))
          ) {
            const { data } = await this.services.ProveedoresProcuctos.getObsProvider(params);
            const elements = [];
            data.forEach((obs) => {
              elements.push({
                id: obs.obs_id,
                codigo: obs.obs_codigo,
                nombre: obs.obs_nombre,
                codigoNombre: `${obs.obs_codigo} - ${obs.obs_nombre}`,
              });
            });
            this.listado_obs_select_new = elements;
          }
          this.isLoading = false;
        }, 500);
      }
    },
    setObsSeleected(value = "") {
      if (value === "") this.selected_obs = this.searchParam;
      if (value === null) this.selected_obs = null;
    }
  },
  created() {
    this.fetchContactType();
    this.getDocumentTypeList();
  },
};
</script>

<style lang="scss" scoped>
:deep(.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th) {
   text-align: center !important;
 }
</style>
