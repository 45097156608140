<template>
  <section fluid>
    <p class="secondary--text mb- text-h6">
      Perfil del proveedor - Agregar contactos
    </p>
    <v-row class="mt-12">
      <v-col cols="12">
        <v-btn
          @click="showRegisterModal = true"
          color="secondary white--text px-sm-8"
        >
          Agregar contacto
        </v-btn>
        <v-dialog v-model="showRegisterModal" max-width="800px">
          <registroContactoModal
            @closeModal="
              (showRegisterModal = false), clearContactInfo(), clearNewObsList()
            "
            @reloadContactList="getContactsList()"
          />
        </v-dialog>
      </v-col>
      <v-col>
        <p class=" primary--text">Listado de contactos</p>
        <data-table-component
          v-models:select="perPage"
          v-models:pagina="page"
          :headers="headers"
          :items="contactsList"
          @paginar="paginate"
          :total_registros="totalRows"
        >
          <template #[`item.id`]="{ item }">
            {{
              firstElement(
                contactsList
                  .map((col) => col.id)
                  .reverse()
                  .indexOf(item.id) + 1
              )
            }}
          </template>
          <template v-slot:[`item.nombre`]="{ item }">
            {{ item.nombre.toUpperCase() }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="configuration(item)">
                  mdi-pencil
                </v-icon>
              </template>
              <span> Editar </span>
            </v-tooltip>
            <v-tooltip top color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteOption(item)"
                    color="red"
                    :disabled="contactsList.length === 1 ? true : false"
                >
                  mdi-trash-can
                </v-icon>
              </template>
              <span> Eliminar </span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-col>
      <v-col cols="12" class="mt-4">
        <v-btn outlined @click="$router.push({ name: 'dashboard' })">
          Regresar
        </v-btn>
      </v-col>
    </v-row>
    <ConfirmationDialog
      title="¿Desea remover el contacto seleccionado?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      :show="showDeleteModal"
      @confirm="deleteItem"
      @close="showDeleteModal = false"
    />
    <v-dialog
      v-model="showUpdateModal"
      max-width="800px"
      @click:outside="clearContactInfo(), clearNewObsList()"
    >
      <registroContactoModal
        isUpdate
        @closeModal="
          (showUpdateModal = false), clearContactInfo(), clearNewObsList()
        "
        @reloadContactList="getContactsList()"
      />
    </v-dialog>
  </section>
</template>

<script>
import registroContactoModal from "@/views/PerfilProveedor/components/RegistroContactoModalComponent.vue";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import AppPaginationComponent from "@/components/AppPaginationComponent.vue";
import DataTableComponent from "../../components/DataTableComponent.vue";
import { mapMutations, mapActions } from "vuex";
export default {
  name: "agregarContactoView",
  components: {
    registroContactoModal,
    ConfirmationDialog,
    AppPaginationComponent,
    DataTableComponent,
  },
  data() {
    return {
      // Variables de paginacion
      totalRows: 0,
      page: 1,
      perPage: 10,
      headers: [
        { text: "N°", align: "center", value: "id" },
        { text: "Nombre", align: "start", value: "nombre" },
        {
          text: "Número de identificación",
          align: "start",
          value: "numero_documento",
        },
        { text: "Correo", align: "start", value: "correo_electronico", sortable: false },
        { text: "Acciones", align: "center", value: "actions", sortable:false },
      ],
      contactsList: [],
      showDeleteModal: false,
      showRegisterModal: false,
      showUpdateModal: false,
      itemToDelete: null,
    };
  },
  methods: {
    ...mapMutations("proveedores", [
      "setUpdatedContact",
      "clearContactInfo",
      "clearNewObsList",
      "setDuiVerified",
    ]),
    ...mapActions("proveedores", ["verifyDui"]),
    goBack() {
      this.$router.push({ name: "perfil_proveedor" });
    },
    deleteOption(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item.id;
    },
    async deleteItem() {
      const response = await this.services.Proveedores.deleteProviderContacts(
        this.itemToDelete
      );
      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Contacto eliminado con éxito",
        });
        this.itemToDelete = null;
        this.showDeleteModal = false;
        this.getContactsList();
      }
    },
    async configuration(item) {
      const response = await this.services.Proveedores.getProviderContact(
        item.id
      );
      if (response.status === 200) {
        this.setUpdatedContact(response?.data);
        this.showUpdateModal = true;

        this.setDuiVerified(false);
        if (response.data.id_tipo_documento === 1) {
          this.verifyDui();
        }
      }
    },
    async getContactsList(filters = {}) {
      const response = await this.services.Proveedores.getProviderContacts(
        filters
      );
      if (response.status === 200) {
        this.contactsList = response?.data;
        this.totalRows = Number(response.headers.total_rows);
      }
    },
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getContactsList({
        page: pagina,
        per_page: cantidad_por_pagina,
      });
    },
    showRestElements() {
      const restos = this.perPage * this.page;
      if (restos <= this.totalRows) return restos;
      else return restos - (restos - this.totalRows);
    },
    firstElement(value) {
      return this.showRestElements() - value + 1;
    },
  },
  created() {
    this.getContactsList({
      page: this.page,
      per_page: this.perPage,
    });
  },
};
</script>
